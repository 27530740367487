import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "src/components/structure/layout";
import PageHeading from "src/components/page-heading";
import JobApplicationForm from "../components/job-application-form";

const CareerTemplate = ({ data }) => {
  const career = data.mdx;
  return (
    <Layout pageTitle={career.frontmatter.title}>
      <PageHeading
        title={career.frontmatter.title}
        image="working"
        crumbs={["TechSlice", "Career", career.frontmatter.title]}
      ></PageHeading>
      <div className="container" style={{ marginBottom: "2rem" }}>
        <MDXRenderer>{career.body}</MDXRenderer>
      </div>
      <div className="container" style={{ marginBottom: "4rem" }}>
        <h2>Apply For This Position:</h2>
        <JobApplicationForm
          jobName={career.frontmatter.title}
        ></JobApplicationForm>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String!) {
    mdx(slug: { eq: $slug }) {
      body
      frontmatter {
        title
      }
    }
  }
`;

export default CareerTemplate;
