import * as React from "react";
import styled from "styled-components";
import {
  FormGroup,
  FormButton,
  FormInput,
  FormTextarea,
  FormWrapper,
} from "./styled/forms";
import { device } from "./styled/sitewide";

const Content = styled.div`
  h2,
  h3 {
    color: var(--primary);
  }
`;

const SubmitButton = styled(FormButton)`
  border: 0;
  background-color: var(--primary);
  color: var(--text-over-primary);
  padding: 0.75rem 2rem;
  margin-top: 1.5rem;

  @media ${device.mobile} {
    padding: 0.75rem 6rem;
  }
`;

const JobApplicationForm = ({ jobName }) => {
  return (
    <Content>
      <form
        method="post"
        netlify-honeypot="bot-field"
        data-netlify="true"
        name="job-application"
        encType="multipart/form-data"
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="job-application" />
        <input type="hidden" name="job-name" value={jobName} />
        <FormGroup>
          <FormInput name="name" placeholder="Your Name" required />
        </FormGroup>
        <FormGroup>
          <FormInput name="email" placeholder="Your Email Address" required />
        </FormGroup>
        <FormGroup>
          <FormInput name="phone" placeholder="Your Phone Number" required />
        </FormGroup>
        <FormGroup>
          <FormTextarea
            name="cover-letter"
            placeholder="Cover Letter"
          ></FormTextarea>
        </FormGroup>
        <FormGroup>
          <FormWrapper>
            <label>
              Resume: <input type="file" name="resume" required />
            </label>
          </FormWrapper>
        </FormGroup>
        <FormGroup>
          <SubmitButton type="submit">Submit</SubmitButton>
        </FormGroup>
      </form>
    </Content>
  );
};
export default JobApplicationForm;
